var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',[_c('vs-row',[_c('h4',{staticClass:"mb-5"},[_vm._v("Add Role")])]),_c('vs-row',{attrs:{"vs-w":"12"}},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('vs-input',{staticClass:"w-full",attrs:{"icon-pack":"feather","icon":"icon-lock","icon-no-border":"","label":"Role Name (*)"},model:{value:(_vm.payload.name),callback:function ($$v) {_vm.$set(_vm.payload, "name", $$v)},expression:"payload.name"}}),_c('span',{staticClass:"block text-sm mt-2 text-danger"},[_vm._v(_vm._s(_vm.errors.first("name")))])],1),_c('vs-col',{attrs:{"vs-w":"12"}},[_c('vx-card',{staticClass:"mt-base",attrs:{"no-shadow":"","card-border":""}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"flex items-end px-3"},[_c('feather-icon',{staticClass:"mr-2",attrs:{"svgClasses":"w-6 h-6","icon":"LockIcon"}}),_c('span',{staticClass:"font-medium text-lg leading-none"},[_vm._v("Permissions (*)")])],1),_c('vs-divider')],1)]),_c('div',{staticClass:"block overflow-x-auto"},[_c('table',{staticClass:"w-full"},[_c('tr',_vm._l(([
                  'Module',
                  'Access',
                  'Browse',
                  'Create',
                  'Read',
                  'Update',
                  'Delete',
                  'All'
                ]),function(heading){return _c('th',{key:heading,staticClass:"font-semibold text-base text-left px-3 py-2"},[_vm._v(" "+_vm._s(heading)+" ")])}),0),_vm._l((_vm.permissions),function(permission){return _c('tr',{key:permission.name},[_c('td',{staticClass:"px-3 py-2"},[_vm._v(_vm._s(_vm._f("capitalize")(permission.name)))]),_vm._l((permission.val),function(val,i){return _c('td',{key:i,staticClass:"px-3 py-2"},[_c('vs-checkbox',{attrs:{"vs-value":val},model:{value:(_vm.payload.permissions),callback:function ($$v) {_vm.$set(_vm.payload, "permissions", $$v)},expression:"payload.permissions"}})],1)}),(permission.val.length >= 5)?_c('td',[_c('vs-checkbox',{attrs:{"vs-value":permission.val},model:{value:(_vm.all_permission),callback:function ($$v) {_vm.all_permission=$$v},expression:"all_permission"}})],1):_vm._e()],2)})],2)])])],1),_c('vs-col',{staticClass:"mt-5",attrs:{"vs-w":"12"}},[_c('div',{staticClass:"vx-col w-full",on:{"click":function($event){$event.preventDefault();return _vm.storeData.apply(null, arguments)}}},[_c('vs-button',{staticClass:"mr-3 mb-2"},[_vm._v("Submit")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }