<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add Role</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12">
        <vs-input
          class="w-full"
          icon-pack="feather"
          icon="icon-lock"
          icon-no-border
          label="Role Name (*)"
          v-model="payload.name"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("name")
        }}</span>
      </vs-col>

      <vs-col vs-w="12">
        <!-- Permissions -->
        <vx-card class="mt-base" no-shadow card-border>
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex items-end px-3">
                <feather-icon
                  svgClasses="w-6 h-6"
                  icon="LockIcon"
                  class="mr-2"
                />
                <span class="font-medium text-lg leading-none"
                  >Permissions (*)</span
                >
              </div>
              <vs-divider />
            </div>
          </div>

          <div class="block overflow-x-auto">
            <table class="w-full">
              <tr>
                <!--
                        You can also use `Object.keys(Object.values(data_local.permissions)[0])` this logic if you consider,
                        our data structure. You just have to loop over above variable to get table headers.
                        Below we made it simple. So, everyone can understand.
                        -->
                <th
                  class="font-semibold text-base text-left px-3 py-2"
                  v-for="heading in [
                    'Module',
                    'Access',
                    'Browse',
                    'Create',
                    'Read',
                    'Update',
                    'Delete',
                    'All'
                  ]"
                  :key="heading"
                >
                  {{ heading }}
                </th>
              </tr>

              <tr v-for="permission in permissions" :key="permission.name">
                <td class="px-3 py-2">{{ permission.name | capitalize }}</td>
                <td
                  v-for="(val, i) in permission.val"
                  class="px-3 py-2"
                  :key="i"
                >
                  <vs-checkbox v-model="payload.permissions" :vs-value="val" />
                </td>
                <td v-if="permission.val.length >= 5">
                  <vs-checkbox v-model="all_permission" :vs-value="permission.val" />
                </td>
              </tr>
            </table>
          </div>
        </vx-card>
      </vs-col>

      <vs-col vs-w="12" class="mt-5">
        <div class="vx-col w-full" @click.prevent="storeData">
          <vs-button class="mr-3 mb-2">Submit</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";

// Store Module
import moduleRoleManagement from "@/store/role-management/moduleRoleManagement.js";

export default {
  name: "RoleAdd",

  metaInfo: {
    title: "Add Role",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  watch: {
    all_permission(permissions) {
      this.payload.permissions = permissions.flat()
    }
  },

  data: () => ({
    payload: {
      name: "",
      permissions: [],
    },

    permissions: [],
    all_permission: []
  }),

  methods: {
    getPermissions() {
      axios
        .get("/permissions")
        .then(({ data: res }) => {
          this.permissions = Object.entries(res.data).map((item, key) => {
            return {
              name: item[0],
              val: item[1].map((item) => item.id),
            };
          });
        })
        .catch((err) => console.log(err));
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);
      formData.append("permissions", JSON.stringify(this.payload.permissions));

      this.$store
        .dispatch("roleManagement/storeData", formData)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success",
          });

          this.$router.push("/roles");
        })
        .catch((err) => {
          this.$catchErrorResponse(err);
        });
    },
  },

  created() {
    if (!moduleRoleManagement.isRegistered) {
      this.$store.registerModule("roleManagement", moduleRoleManagement);
      moduleRoleManagement.isRegistered = true;
    }

    this.getPermissions();
  },
};
</script>

<style></style>
